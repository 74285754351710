import React from 'react';
import './header.scss'
import {Icon} from "../icon/Icon";
import Select from '@mui/base/Select';
import Option, { optionClasses } from '@mui/base/Option';
import Popper from '@mui/base/Popper';
import { styled} from '@mui/system';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import Avatar from '../../assets/photo.png'
import NoAvatar from '../../assets/nophoto.png'
import {Link} from "react-router-dom";
const msgData = [
    {title:'Message',desc:'message preview',read:false,time:'5 min ago'},
    {title:'Message',desc:'message preview',read:false,time:'1 h ago'},
    {title:'Message',desc:'message preview',read:false,time:'21/03/2023'},
    {title:'Message',desc:'message preview',read:true,time:'18/03/2023'},
    {title:'Message',desc:'message preview',read:true,time:'15/03/2023'},
    {title:'Message',desc:'message preview',read:true,time:'12/03/2023'},
    {title:'Message',desc:'message preview',read:true,time:'11/03/2023'},
]
const notData = [
    {title:'Trade Status',desc:'Operation 1 was cancelled',read:false,time:'5 min ago'},
    {title:'New NFT',desc:'You bought a new NFT',read:false,time:'1 h ago'},
    {title:'Trade Status',desc:'Operation 2 was done',read:false,time:'21/03/2023'},
    {title:'New NFT',desc:'You bought a new NFT',read:true,time:'18/03/2023'},
    {title:'New NFT',desc:'You bought a new NFT',read:true,time:'15/03/2023'},
    {title:'New NFT',desc:'You bought a new NFT',read:true,time:'12/03/2023'},
    {title:'New NFT',desc:'You bought a new NFT',read:true,time:'11/03/2023'},
]

function burgerMenu() {
    if(document.body.classList.contains('open')) {
        document.body.classList.remove('lock','open')
    } else {
        document.body.classList.add('lock','open')
    }
}

const Header = () => {


    const [anchorEl, setAnchorEl] = React.useState(false);
    const [anchorElNotice, setAnchorElNotice] = React.useState(false);
    const [anchorElProfile, setAnchorElProfile] = React.useState(false);

    const openMessage = (event) => {
        setAnchorEl(anchorEl ? false : event.currentTarget);
    };
    const handleCloseMsg = () => {
        setAnchorEl(false);
    };
    const handleCloseNotice = () => {
        setAnchorElNotice(false);
    };
    const openNotice = (event) => {
        setAnchorElNotice(anchorElNotice ? false : event.currentTarget);
    };
    const openProfile = (event) => {
        setAnchorElProfile(anchorElProfile ? false : event.currentTarget);
    };
    const handleCloseProfile = () => {
        setAnchorElProfile(false);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'message-popper' : undefined;
    const openNoticePopper = Boolean(anchorElNotice);
    const idNotice = openNoticePopper ? 'notice-popper' : undefined;
    const openProfilePopper = Boolean(anchorElProfile);
    const idProfile = openProfilePopper ? 'notice-profile' : undefined;
    let message = true;
    let notice = false;
    let photo = true;

    const [modalOpen, setModalOpen] = React.useState(false);
    const handleOpen = () => setModalOpen(true);
    const handleClose = () => setModalOpen(false);

    return (
        <header className='header'>
            <div className="header__container">
                <a href='https://lumeta.io/'  className="header__logo"><img src="assets/image/logo.png" alt=""/></a>
                <div className="header__language">
                    <CustomSelect defaultValue={'EN'} id="lang-select" name="language-select">
                        <StyledOption  value='EN'>EN</StyledOption >
                        <StyledOption  value='RU'>RU</StyledOption >
                        <StyledOption  value='AR'>AR</StyledOption >
                        <StyledOption  value='ZH'>ZH</StyledOption >
                        <StyledOption  value='HI'>HI</StyledOption >
                    </CustomSelect>
                </div>
                <div className="header__notice">
                    <ClickAwayListener onClickAway={handleCloseMsg}>
                        <div>
                            <div className="header__notice-mail" aria-describedby={id} onClick={openMessage}>{ message ? <Icon name='mail-act'/> : <Icon name='mail'/>}</div>
                            <Popper id={id} open={open} anchorEl={anchorEl}>
                            <div className='pop-notice'>
                                { msgData.map( (data,index) => {
                                    return (
                                        <Notice key={index} title={data.title} desc={data.desc} time={data.time} read={data.read} action='message'/>
                                    );
                                })}
                            </div>
                        </Popper>
                        </div>
                    </ClickAwayListener>
                    <ClickAwayListener onClickAway={handleCloseNotice}>
                        <div>
                            <div className="header__notice-notice" aria-describedby={idNotice} onClick={openNotice}>{ notice ? <Icon name='notice-act'/> : <Icon name='notice'/>}</div>
                            <Popper id={idNotice} open={openNoticePopper} anchorEl={anchorElNotice}>
                                <div className='pop-notice'>
                                    { notData.map( (data,index) => {
                                        return (
                                            <Notice key={index} title={data.title} desc={data.desc} time={data.time} read={data.read} action='notice'/>
                                        );
                                    })}
                                </div>
                            </Popper>
                        </div>
                    </ClickAwayListener>
                </div>
                <ClickAwayListener onClickAway={handleCloseProfile}>
                    <div>
                        <div className="header__profile" aria-describedby={idProfile} onClick={openProfile}><img src="assets/image/avatar.png" alt=""/></div>
                        <Popper id={idProfile} open={openProfilePopper} anchorEl={anchorElProfile}>
                            <div className='pop-profile'>
                                <div className="pop-profile__img">
                                    <img src={photo ? Avatar : NoAvatar} alt=""/>
                                </div>
                                {photo ? (
                                    <>
                                        <button className="pop-profile__change">Change photo</button>
                                        <button className="pop-profile__delete">Delete photo</button>
                                    </>
                                ) : (
                                    <button className="pop-profile__change">Upload photo</button>
                                )}
                            </div>
                        </Popper>
                    </div>
                </ClickAwayListener>
                <div className="header__setting"><Icon name='setting'/></div>
                <div className="header__burger" onClick={()=> burgerMenu()}>
                    <button><Icon name='burger'/></button></div>
            </div>
        </header>
    );
};

export default Header;

const CustomSelect = React.forwardRef(function CustomSelect(props, ref) {
    const slots = {
        listbox: StyledListBox,
        popper: StyledPopper,
        ...props.slots,
    };

    return <Select {...props} ref={ref} slots={slots} />;
});
const StyledListBox = styled('ul')(
    ({ theme }) => `
  font-size: 12px;
  font-weight: 600;
  text-align:center;
  width: 56px;
  padding-top:15px;
  padding-bottom:15px;
  overflow: auto;
  outline: 0px;
  border-radius: 8px;
  background: #FFF;
  box-shadow: 0px 4px 17px 0px rgba(217, 217, 217, 0.25);
  color: #A7A7A7;
  `,
);
const StyledOption = styled(Option)(
    ({ theme }) => `
  list-style: none;
  cursor: pointer;
  margin-bottom:6px;
  &:last-of-type {
    border-bottom: none;
    margin-bottom:0;
  }
   &.${optionClasses.highlighted}.${optionClasses.selected} {
    color: #4ABEF0;
  }
  `,
);
const StyledPopper = styled(Popper)`
  z-index: 1;
`;

const Notice = ({title,desc,read,time,action}) => {
    return (
        <div className={`notice ${ read ? 'read' : 'active '}`}>
            <div className="notice__img"> <Icon name={action === 'message' ? 'message' : 'not-icon'}/></div>
            <div className="notice__info">
                <div className="notice__info-title">{title}</div>
                <div className="notice__info-desc">{desc}</div>
            </div>
            <div className="notice__time">{time}</div>
        </div>
    )

}



