import React from 'react';
import './plan.scss'
import {Icon} from "../../components/icon/Icon";

const Plan = () => {
    return (
        <div className='main-page'>
            <h2 className="admin-content__title">Saving plan</h2>
            <div className="main__wrapper">
                <div className="main__title">
                    In Development
                    <Icon name='time'/>
                </div>
            </div>
        </div>
    );
};

export default Plan;
