import React from 'react';
import './adminlayout.scss'
import Header from "../header/Header";
import Sidebar from "../sidebar/Sidebar";

function overlay() {
    if(document.body.classList.contains('lock')) {
        document.body.classList.remove('lock','open')
    } else {
        document.body.classList.add('lock','open')
    }
}

const AdminLayout = ({ children }) => {
    return (
        <div>
            <Header/>
            <div className="admin-content">
                <Sidebar/>
                <main>{children}</main>
            </div>
            <div className="overlay" onClick={() => overlay()}></div>
        </div>
    );
};

export default AdminLayout;
