import React, {useEffect, useState} from 'react';
import './overview.scss'
import { AreaChart , CartesianGrid, XAxis, YAxis, Area,Tooltip,ResponsiveContainer  } from 'recharts';
import {Icon} from "../icon/Icon";
const data = [
    {name: '15.01', uv: 17000, pv: 2400, amt: 2400},
    {name: '16.01', uv: 25000, pv: 2400, amt: 2400},
    {name: '17.01', uv: 15000, pv: 2400, amt: 2400},
    {name: '18.01', uv: 4000, pv: 2400, amt: 2400},
    {name: '19.01', uv: 10000, pv: 2400, amt: 2400},
    {name: '20.01', uv: 7000, pv: 2400, amt: 2400},
    {name: '21.01', uv: 6000, pv: 2400, amt: 2400},
];
const data2 = [
    {name: '1.01', uv: 10000, pv: 2400, amt: 2400},
    {name: '7.01', uv: 5000, pv: 2400, amt: 2400},
    {name: '14.01', uv: 30000, pv: 2400, amt: 2400},
    {name: '21.01', uv: 8000, pv: 2400, amt: 2400},
    {name: '28.01', uv: 30000, pv: 2400, amt: 2400},
    {name: '5.01', uv: 7000, pv: 2400, amt: 2400},
    {name: '12.01', uv: 3000, pv: 2400, amt: 2400},
];
const data3 = [
    {name: '2015', uv: 5000, pv: 2400, amt: 2400},
    {name: '2016', uv: 10000, pv: 2400, amt: 2400},
    {name: '2017', uv: 15000, pv: 2400, amt: 2400},
    {name: '2018', uv: 25000, pv: 2400, amt: 2400},
    {name: '2019', uv: 35000, pv: 2400, amt: 2400},
    {name: '2020', uv: 7000, pv: 2400, amt: 2400},
    {name: '2021', uv: 6000, pv: 2400, amt: 2400},
];
const formatYAxisTick = (value) => {
    if (value >= 1000) {
        return `${value / 1000}k`;
    }
    return value;
};

const findPreviousElement = (currentName, data) => {
    const currentIndex = data.findIndex(item => item.name === currentName);
    const previousIndex = currentIndex - 1;

    if (previousIndex >= 0) {
        return data[previousIndex];
    }

    return null;
};
const calculatePercentageChange = (oldValue, newValue) => {
    if(newValue && oldValue) {
        const difference = newValue - oldValue;
        let percent = (difference / oldValue) * 100;
        return parseInt(percent);
    }
    return 0;
};
const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
        const dataPoint = payload[0].payload;
        const previousElement = findPreviousElement(dataPoint.name, data);
        const prevDay = previousElement ? previousElement.uv : 0;
        const percent = calculatePercentageChange(prevDay,dataPoint.uv);
        let sum = dataPoint.uv - prevDay;
        if(sum >=0){ sum = `+$${sum}`}else{sum = `-$${sum*-1}`}
        return (
                <div className="custom-tooltip">
                    <p className="uv">{`${sum} (${percent}%)`}</p>
                    <Icon name='Tooltip' className='tool-back'/>
                </div>
        );
    }

    return null;
};
const CustomActiveDot = (props) => {
    const { cx, cy, stroke, strokeWidth } = props;
    return (
        <circle cx={cx} cy={cy} r={6} fill="#4ABEF0" stroke='white' strokeWidth='4' />
    );
};

export const Profit = ({title, value}) => {
    return (
        <div className="overview__item">
            <Icon name={title}/>
            <div className="overview__item-info">
                <div className="overview__item-name">{title}</div>
                <div className="overview__item-value">${value}</div>
            </div>
        </div>
    );
}

const Overview = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [showInitials, setShowInitials] = useState(windowWidth <= 600);
    const [chart, setChart] = useState(data);
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
            setShowInitials(window.innerWidth <= 600);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    const [activePoint, setActivePoint] = useState({ x: 0, y: 0 });

    const PositionToolTip = (e) => {
        const tooltip = document.querySelector('.recharts-tooltip-wrapper');
        const activeDot = document.querySelector('.recharts-active-dot circle');
        let cx = 0,cy=0;
        if (activeDot) {
            cx = parseFloat(activeDot.getAttribute('cx'));
            cy = parseFloat(activeDot.getAttribute('cy'));
        }
        if (!tooltip) return;
        const tooltipWidth = tooltip.clientWidth;
        const tooltipHeight = tooltip.clientHeight;
        setActivePoint({
            x: cx - tooltipWidth/2,
            y: cy - tooltipHeight - 25
        });
    }
    window.onload = function () {
        document.querySelectorAll('.overview__top-btn button').forEach( btn => {
            btn.addEventListener('click', () => {
                document.querySelectorAll('.overview__top-btn button').forEach(otherBtn => {
                    if (otherBtn !== btn) {
                        otherBtn.classList.remove('active');
                    }
                });
                btn.classList.add('active')

                switch (btn.getAttribute('data-initial')) {
                    case 'D':
                        setChart(data)
                        break;
                    case 'W':
                        setChart(data2)
                        break;
                    case 'M':
                        setChart(data2)
                        break;
                    case 'Y':
                        setChart(data3)
                        break;
                    case 'A':
                        setChart(data)
                        break;
                    default:
                        setChart(data)
                        break;
                }
            })
        })
    }
    return (
        <div className='overview'>
            <div className="overview__top">
                <div className="home__widget-title">Overview</div>
                <div className="overview__top-btn">
                    <button className={showInitials ? 'active' : ''} data-initial="D">
                        {showInitials ? 'D' : 'Day'}
                    </button>
                    <button data-initial="W">{showInitials ? 'W' : 'Week'}</button>
                    <button data-initial="M">{showInitials ? 'M' : 'Month'}</button>
                    <button data-initial="Y">{showInitials ? 'Y' : 'Year'}</button>
                    <button data-initial="A">{showInitials ? 'A' : 'All'}</button>
                </div>
            </div>
            <div className="overview__middle">
                <div className="overview__balance">
                    <div className='count'>$6936</div>
                    <div className='sub-count'>Current Balance</div>
                </div>
                <div className="AreaChartWrapper">
                    <ResponsiveContainer width="100%" height="100%">
                        <AreaChart className="AreaChart"  data={chart} margin={{ top: 5, right: 20, bottom: 5, left: 0 }} onMouseMove={PositionToolTip} >
                            <defs>
                                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor="#4ABEF0" stopOpacity={0.5} />
                                    <stop offset="75%" stopColor="#4ABEF0" stopOpacity={0} />
                                </linearGradient>
                            </defs>
                            <CartesianGrid stroke="#F0F0F0" strokeDasharray="none" />
                            <XAxis dataKey="name"  axisLine={false} tickLine={false}/>
                            <YAxis tickFormatter={formatYAxisTick}  axisLine={false} tickLine={false}/>
                            <Area type="monotone" connectNulls activeDot={<CustomActiveDot />}  dataKey="uv" stroke="#4ABEF0" strokeWidth='4px' fillOpacity={1} fill="url(#colorUv)" />
                            <Tooltip position={activePoint} cursor={false} content={CustomTooltip}/>
                        </AreaChart >
                    </ResponsiveContainer>
                </div>
            </div>
            <div className="overview__bottom">
                <Profit title='Spot' value={3468}/>
                <Profit title='Referral Earning' value={1723}/>
                <Profit title='Trade' value={542}/>
                <Profit title='NFT Passive Income' value={1203}/>
            </div>
        </div>
    );
};

export default Overview;
