import React from 'react';
import './topnft.scss'
import { styled } from '@mui/material/styles';
import {Icon} from "../../icon/Icon";
import nftImg from '../../../assets/nft.png';

const CustomBtn = styled("button")(({ theme }) => ({
    marginRight: '10px',
    color:' #DBDBDB',
    fontSize: '14px',
    fontWeight: '600',
    lineHeight: '20px',
    '&.active': {
        color: '#53B7EA',
    }
}));
const TopNft = () => {
    const data = [
        {img: nftImg, name:'Name###NFT',price:'2 ETH',href:'#'},
        {img: nftImg, name:'Name###NFT',price:'2 ETH',href:'#'},
        {img: nftImg, name:'Name###NFT',price:'2 ETH',href:'#'},
        {img: nftImg, name:'Name###NFT',price:'2 ETH',href:'#'},
        {img: nftImg, name:'Name3333NFT',price:'2 ETH',href:'#'},
        {img: nftImg, name:'Name22#NFT',price:'2 ETH',href:'#'},
        {img: nftImg, name:'Name##1NFT',price:'2 ETH',href:'#'},
        {img: nftImg, name:'Name#2#NFT',price:'2 ETH',href:'#'}]

    const [page, setPage] = React.useState(0);
    const rowsPerPage = 4;

    const totalPages = Math.ceil(data.length / rowsPerPage);
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
    }
    const handleChangePage = (event, newPage) => {
        (newPage <= totalPages-1) ? setPage(newPage) : setPage(totalPages-1) ;
    };
    return (
        <div className='topNft'>
            <div className="topNft__wrapper">
                {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((card, index) => (
                    <div className="topNft__card" key={index}>
                        <div className="topNft__img">
                            <img src={card.img} alt="" />
                        </div>
                        <div className="topNft__info">
                            <div className="topNft__name">{card.name}</div>
                            <div className="topNft__price">
                                <Icon name='eth' /> {card.price}
                            </div>
                        </div>
                        <div className="topNft__button">buy on <span>OpenSea</span></div>
                        <a href={card.href}>go to my nft</a>
                    </div>
                ))}
            </div>
            <div className="pagination-table pagination-nft">
                {pageNumbers.map((pageNumber) => (
                    <CustomBtn className={pageNumber-1 === page ? 'active' : ''} key={pageNumber} onClick={(event) => handleChangePage(event,pageNumber-1)}>
                        {pageNumber}
                    </CustomBtn>
                ))}
                <button onClick={(event) => handleChangePage(event,page+1)} > <Icon name={'arrow-right'}/></button>
            </div>
        </div>
    );
};

export default TopNft;
