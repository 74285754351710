import React, {useState} from 'react';
import './support.scss'
import {Icon} from "../../components/icon/Icon";


function validateForm (event) {
    event.preventDefault();
    const name = document.getElementById('name');
    const mail = document.getElementById('mail');
    const agr = document.getElementById('agree');
    if(name.value.length <= 0 ) {
        return name.parentElement.classList.add('warning');
    } else {name.parentElement.classList.remove('warning');}
    if(mail.value.length <= 0 ) {
        return mail.parentElement.classList.add('warning');
    } else {mail.parentElement.classList.remove('warning');}
    if(agr.checked == false) {
        return agr.parentElement.classList.add('warning');
    } else {agr.parentElement.classList.remove('warning');}
}


const Supports = () => {
    const [file, setFile] = useState(false);
    const [fileName, setFileName] = useState('Attach a screenshot (png, jpg)');
    function fileCheck (event) {
        const text = event.target.files[0].name;
        text ?  setFile(true) :  setFile(false);
        setFileName(text)
    }
    return (
        <div className='support'>
            <h2 className="admin-content__title">Supports</h2>
            <div className="support__sub-title">Write your opinion, suggestion or complaint. We will consider and answer all questions</div>
            <div className="support__wrapper">
                <form action="">
                    <div className="support__input-wrapper">
                        <div className="support__input">
                            <input  id='name' type="text" placeholder='Enter your name' required/>
                            <Icon name='warning' className='wr'/>
                            <Icon name='inputBorder' className='input-border'/>
                            <Icon name='inputBrWar' className='input-border-wr'/>
                        </div>
                        <div className="support__input">
                            <input id='mail' type="text" placeholder='Enter your email' required/>
                            <Icon name='warning' className='wr'/>
                            <Icon name='inputBorder' className='input-border'/>
                            <Icon name='inputBrWar' className='input-border-wr'/>
                        </div>
                    </div>
                    <div className="support__textarea">
                        <textarea placeholder='Enter the text'></textarea>
                    </div>
                    <div className="support__bottom">
                        <div className="support__file">
                            <input onChange={ (event) => fileCheck(event)} type="file" accept='image/*' id='file'/>
                            { file ?
                                <label htmlFor="file"><Icon name='screp'/><span>{fileName}</span></label>
                            : <label htmlFor="file"> <Icon name='screp'/> <span>Attach a screenshot (png, jpg)</span></label>
                            }
                            { file ? <div style={{cursor:"pointer"}} onClick={() => {
                                console.log('test')
                                setFile(false);
                                setFileName('');
                            }}><Icon name='delet-c' />
                            </div> : null }
                        </div>
                        <div className="support__button">
                            <button onClick={(event) => {validateForm(event)}} >Send <Icon name='twit'/></button>
                        </div>
                    </div>
                    <div className="support__agree">
                        <input type="checkbox" id="agree" required/>
                        <label htmlFor="agree"> <span>I am familiar with and agree with the processing of data <b>by Lumeta</b></span> </label>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Supports;
