import React from 'react';
import './trade.scss'
import {Icon} from "../../components/icon/Icon";
const Trade = () => {
    return (
        <div className='main-page'>
            <h2 className="admin-content__title">Trade</h2>
            <div className="main__wrapper">
                <div className="main__title">
                    In Development
                    <Icon name='time'/>
                </div>
            </div>
        </div>
    );
};

export default Trade;
