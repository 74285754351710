import React from 'react';
import './nft.scss';
import img1 from '../../assets/card1.png';
import img2 from '../../assets/card2.png';
import NftCard from "../../components/nftCard/NftCard";
const data = [
    {name:'Name ###NFT',price:'2,2',currency:'eth',img:img1,action:'sell'},
    {name:'Name ###NFT',price:'2',currency:'eth',img:img2,action:'buy'},
];

const Nft = () => {
    return (
        <div className='nft'>
            <h2 className="admin-content__title">NFT Accets <span className='admin-content__wallet'>2.2 ETH</span></h2>
            <div className="nft__wrapper">
                {data.map((item,index) => (
                    <NftCard key={index} action={item.action} name={item.name} price={item.price} currency={item.currency} img={item.img}/>
                ))}
            </div>
        </div>
    );
};

export default Nft;
