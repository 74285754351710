import React from 'react';
import './recentactivities.scss'
import {Icon} from "../icon/Icon";
const RecentActivities = ({time,action}) => {
    return (
        <div className='recent'>
            <span className='recent__time'>{time}</span>
            <Icon name={action}/>
            <div className="recent__info">
                <span className='recent__action'>{action}</span>
                <span className='recent__details'>Details</span>
            </div>
        </div>
    );
};

export default RecentActivities;
