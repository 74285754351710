import React, {useState,useEffect} from 'react';
import './home.scss'
import {Icon} from "../../components/icon/Icon";
import WatchList from "../../components/watchlist/WatchList";
import RecentActivities from "../../components/recentactivities/RecentActivities";
import Tabs from "../../components/tabs/Tabs";
import Portfolio from "../../components/portfolio/Portfolio";
import Overview from "../../components/overview/Overview";


const Home = () => {

    const [markets, setMarkets] = useState({})
    const [isLoading, setIsLoading] = useState(false)

    const randomNumber = function(minimum, maximum){
        return Math.round( Math.random() * (maximum - minimum) + minimum);
    }

    const getData = async ()=> {
        console.log(isLoading)

        if (isLoading) return false;

        setIsLoading(true);

        //const url = 'api/markets.json';
        const url = 'https://dashboard.lumeta.io/api/markets.php';

        fetch(url,
        {
          headers : { 
            'Content-Type': 'application/json',
            'Accept': 'application/json'
           }
        })
        .then(function(response){
            //console.log(response);
            const json = response.json();
            //console.log(1,json);

            return json;
        })
        .then(function(json) {
            setIsLoading(false);

            console.log(2,json);

            const accept = ['Bitcoin', 'Ethereum', 'BNB']; //'Tether'

            const newJson = json.filter(function (el) {
                return accept.includes(el.name);
            });

            newJson.unshift({
                "id": "bud",
                "symbol": "bud",
                "name": "BUD",
                "current_price": randomNumber(5000,5100),
            });

            setMarkets(newJson);
            
            console.log(3,newJson);
        })
        .catch((err) => {
            setIsLoading(false);
            console.log(4,err.toString());
        });
    }

      useEffect( ()=> {
        getData()
      },[]);

    // const filteredCoins = coins.filter((coin) => {
    //     return coin.name.toLowerCase().includes(searchWord.toLowerCase());
    // });


    return (
        <div className='home'>
            <h2 className="admin-content__title">Dashboard</h2>

            {/* <div className="coins-list">
            {
                markets && markets.length  ? 
                     markets.map((coin) => {
                        return <h3 key={coin.id}>{coin.name} - {coin.current_price}</h3>
                    })
                 : null
            }
            </div> */}


            <div className="home__wrapper-top">
                <div className="home__overview">
                    <Overview/>
                </div>
                <div className="home__portfolio">
                    <div className="home__widget-title">Portfolio</div>
                    <Portfolio/>
                </div>
            </div>
            <div className="home__watchlist">
                <div className="watchlist-title"><span>Watchlist</span> <span className="setting"><Icon name='filter'/></span></div>
                <div className="watchlist-wrapper">
                    {
                    markets && markets.length  ? 
                        markets.map((coin) => {
                            return <WatchList key={coin.id} title={coin.name} count={coin.current_price} />
                        })
                    : null
                    }
                    {/* <WatchList title='BUD' count='5000'/>
                    <WatchList title='Bitcoin' count='27000'/>
                    <WatchList title='Ethereum' count='2000'/>
                    <WatchList title='BNB' count='300.05'/> */}
                </div>
            </div>
            <div className="home__wrapper-bottom">
                <div className="home__recent">
                    <div className="title">Recent Activities</div>
                    <div className="home__recent-wrapper">
                        <RecentActivities action='Saving Made' time='30 min ago'/>
                        <RecentActivities action='Sold' time='15 hours ago'/>
                        <RecentActivities action='Staked' time='1 day ago'/>
                        <RecentActivities action='Farmed' time='2 months ago'/>
                        <RecentActivities action='Bought' time='1 year ago'/>
                        <RecentActivities action='SWAPed' time='1 year ago'/>
                    </div>
                </div>
                <div className="home__status">
                    <Tabs/>
                </div>
            </div>

        </div>
    );
};

export default Home;
