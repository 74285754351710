import React from 'react';
import './nftcard.scss'
import {Icon} from "../icon/Icon";
const NftCard = ({action,name,img,price,currency}) => {
    return (
        <div className='nftCard'>
            <div className="nftCard__img-wrapper">
                <div className="nftCard__test">
                    <div className="nftCard__img">
                        <img src={img} alt=""/>
                    </div>
                </div>
            </div>
            <div className="nftCard__info">
                <div className="nftCard__head">
                    <div className="nftCard__name">{name}</div>
                    <div className="nftCard__price"><Icon name={currency} className='nftCard__icon'/>{price} {currency}</div>
                </div>
                {action === 'sell' ? (
                       <><div className="nftCard__btn-small">
                            <button>sell on OpenSea</button>
                        </div>
                    <button className="nftCard__gallery">Lumeta gallery <Icon name='play'/></button></>
                ) :  <div className="nftCard__btn">
                    <button>buy on OpenSea</button>
                </div>}

            </div>
        </div>
    );
};

export default NftCard;
