import React from 'react';
import './reward.scss'
import {Icon} from "../../icon/Icon";
const Reward = () => {
    let width = '70%';

    return (
        <div className='reward'>
            <div className="rewardProgress"> {width} progress</div>
            <div className="reward__wrapper">
                <div className="reward__progressIcon">
                    <div className={`progressIcon ${width >= "20%" ? "active" : ""}`}>
                        <div className="progressIcon__title-wrap">
                            <div className="progressIcon__title">PADAWAN</div>
                            <div className="progressIcon__count">123</div>
                        </div>
                        <div className="progressIcon__icon"><Icon name='PADAWAN'/></div>
                        <div className="progressIcon__line"></div>
                    </div>
                    <div className={`progressIcon ${width >= "40%" ? "active" : ""}`}>
                        <div className="progressIcon__title-wrap">
                            <div className="progressIcon__title">MASTER</div>
                            <div className="progressIcon__count">123</div>
                        </div>
                        <div className="progressIcon__icon"><Icon name='MASTER'/></div>
                        <div className="progressIcon__line"></div>
                    </div>
                    <div className={`progressIcon ${width >= "60%" ? "active" : ""}`}>
                        <div className="progressIcon__title-wrap">
                            <div className="progressIcon__title">SENSEI</div>
                            <div className="progressIcon__count">123</div>
                        </div>
                        <div className="progressIcon__icon"><Icon name='SENSEI'/></div>
                        <div className="progressIcon__line"></div>
                    </div>
                    <div className={`progressIcon ${width >= "80%" ? "active" : ""}`}>
                        <div className="progressIcon__title-wrap">
                            <div className="progressIcon__title">LAMA</div>
                            <div className="progressIcon__count">123</div>
                        </div>
                        <div className="progressIcon__icon"><Icon name='LAMA'/></div>
                        <div className="progressIcon__line"></div>
                    </div>
                    <div className={`progressIcon ${width >= "95%" ? "active" : ""}`}>
                        <div className="progressIcon__title-wrap">
                            <div className="progressIcon__title">BUDDHA</div>
                            <div className="progressIcon__count">123</div>
                        </div>
                        <div className="progressIcon__icon"><Icon name='BUDDHA'/></div>
                        <div className="progressIcon__line"></div>
                    </div>
                </div>
                <div className="reward__progressBar-wrap">
                    <div className="reward__progressBar">
                            <div className="reward__progressBar-line" style={{width:width}}></div>
                    </div>
                </div>
            </div>
            <div className="reward__info">
                <Icon name='question'/>
                <span>Here, information with a brief description of the awards and what they give to the user is displayed when clicked, the question icon is highlighted when hovering</span>
            </div>
        </div>
    );
};

export default Reward;
