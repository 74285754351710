import React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {Icon} from "../../icon/Icon";
import {useMediaQuery} from "react-responsive";

const CustomTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(4n+4) td': {
        borderBottom:'none'
    },
    '@media(max-width:700px)' : {
        display:'flex',
        flexDirection: 'column',
    }
}));

const CustomTableCell = styled(TableCell)(({ theme }) => ({
    borderBottom: '0.5px solid #C5C5C5',
    color: '#A7A7A7',
    fontFamily: 'Montserrat',
    fontSize: '14px',
    fontWeight: '600',
    lineHeight: '20px',
    padding: '10px 0',
}));
const BodyTableCell = styled(TableCell)(({ theme }) => ({
    borderBottom: '0.5px solid rgba(197, 197, 197, 0.50)',
    color: '#5F5F5F',
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '20px',
    padding: '20px 0',
    verticalAlign: 'top',
     'svg' : {
        marginRight:'7px'
     },
    '&:first-of-type': {
        fontWeight: '600',
    },
     '&:last-child': {
        fontSize:'12px',
        lineHeight: '150%',
         maxWidth:'195px',
     },
    '@media(max-width:700px)' : {
        borderBottom:'none',
        fontSize: '12px',
        '&:last-child': {
            maxWidth:'100%',
        },
        '&:first-of-type': {
            fontSize: '14px',
        },
    },
    '.tc-wrap': {
        display:'flex',
        /*gridTemplateColumns: '65px 1fr',*/
    },
    '.tc-label': {
        flex:'0 0 65px',
        color: '#A7A7A7',
        fontSize: '12px',
        fontWeight: '600',
        lineHeight: '20px',
        marginRight:'40px'
    },
}));
const CustomBtn = styled("button")(({ theme }) => ({
    marginRight: '10px',
    color:' #DBDBDB',
    fontSize: '14px',
    fontWeight: '600',
    lineHeight: '20px',
    '&.active': {
        color: '#53B7EA',
    }
}));

const columns = [
    { id: 'operations', label: 'Operations', minWidth: 170},
    { id: 'status', label: 'Status', minWidth: 150},
    { id: 'amount', label: 'Аmount', minWidth: 150,},
    { id: 'date', label: 'Date', minWidth: 150,},
    { id: 'comment', label: 'Comment', minWidth: 170,},
];
function createData(id,operations, status, amount, date, comment,) {return {id, operations, status, amount, date, comment };}

const rows = [
    createData(0,'Operation Name', 'In process', '24.000 USDT', '7 hours ago', '—'),
    createData(1,'Operation Name', 'In process', '24.000 USDT', '1 day ago', '—'),
    createData(2,'Operation Name', 'Done', '24.000 USDT', '1 day ago', '—'),
    createData(3,'Operation Name', 'Cancelled', '24.000 USDT', '2 weeks ago', 'The payment was not accepted, there were problems with the connection during the operation, try again'),
    createData(4,'Operation Name', 'Cancelled', '24.000 USDT', '2 weeks ago', '—'),
    createData(5,'Operation Name', 'In process', '24.000 USDT', '7 hours ago', '—'),
    createData(6,'Operation Name', 'In process', '24.000 USDT', '1 day ago', '—'),
    createData(7,'Operation Name', 'Done', '24.000 USDT', '1 day ago', '—'),
    createData(8,'Operation Name', 'Cancelled', '24.000 USDT', '2 weeks ago', '—'),
    createData(9,'Operation Name', 'In process', '24.000 USDT', '7 hours ago', '—'),
    createData(10,'Operation Name', 'In process', '24.000 USDT', '1 day ago', '—'),
    createData(11,'Operation Name', 'Done', '24.000 USDT', '1 day ago', '—'),
    createData(12,'Operation Name', 'Cancelled', '24.000 USDT', '2 weeks ago', '—'),
];



const TradeStatus = () => {
    const isMobile = useMediaQuery({ maxWidth: 700 });
    const [page, setPage] = React.useState(0);
    const rowsPerPage = isMobile ? 3 : 4;
    const totalPages = Math.ceil(rows.length / rowsPerPage);
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
    }
    const handleChangePage = (event, newPage) => {
        (newPage <= totalPages-1) ? setPage(newPage) : setPage(totalPages-1) ;
    };
    return (
        <div style={{height:'100%',width: '100%',display:'flex',flexDirection:'column', }}>
            <TableContainer sx={ isMobile ?{maxHeight: '100%' } : {maxHeight: 440 }}>
                <Table>
                    { isMobile ? null : <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <CustomTableCell
                                    key={column.id}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    {column.label}
                                </CustomTableCell>
                            ))}
                        </TableRow>
                    </TableHead>}
                    <TableBody>
                        {rows
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row) => {
                                return (
                                    <CustomTableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                        {columns.map((column) => {
                                            const value = row[column.id];
                                            return (
                                                <BodyTableCell key={column.id}>
                                                    {column.id === 'status' ? (
                                                        isMobile ? (
                                                            <div className='tc-wrap'>
                                                                <div className='tc-label'>{column.label}</div>
                                                                <Icon name={value} /> {value}
                                                            </div>
                                                        ) : (
                                                        <div style={{display: 'flex',alignItems:'center'}}>
                                                            <Icon name={value} /> {value}
                                                        </div>
                                                        )
                                                    ) : (
                                                        isMobile && column.label !== 'Operations' ? (
                                                            <div className='tc-wrap'>
                                                                <div className='tc-label'>{column.label}</div>
                                                                <div>{value}</div>
                                                            </div>
                                                        ) : (
                                                            value
                                                        )
                                                    )}
                                                </BodyTableCell>
                                            );
                                        })}
                                    </CustomTableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <div className="pagination-table">
                {pageNumbers.map((pageNumber) => (
                    <CustomBtn className={pageNumber-1 === page ? 'active' : ''} key={pageNumber} onClick={(event) => handleChangePage(event,pageNumber-1)}>
                        {pageNumber}
                    </CustomBtn>
                ))}
                <button onClick={(event) => handleChangePage(event,page+1)} > <Icon name={'arrow-right'}/></button>
            </div>
        </div>
    );
};

export default TradeStatus;
