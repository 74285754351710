import React from 'react';
import { styled } from '@mui/system';
import TabContainer from '@mui/base/Tabs';
import TabsList from '@mui/base/TabsList';
import TabPanel from '@mui/base/TabPanel';
import { buttonClasses } from '@mui/base/Button';
import Tab, { tabClasses } from '@mui/base/Tab';

import TradeStatus from "./tradeStatus/TradeStatus";
import TopNft from "./topNft/TopNft";
import Reward from "./rewards/Reward";


const Tabs = () => {


    return (
        <StyledTabContainer defaultValue={0}>
            <StyledTabsList>
                <StyledTab value={0}>Trade Status</StyledTab>
                <StyledTab value={1}>Top NFT</StyledTab>
                <StyledTab value={2}>Rewards</StyledTab>
            </StyledTabsList>
            <TabPanel style={{height:'100%'}} value={0}><TradeStatus/></TabPanel>
            <TabPanel style={{height:'100%'}} value={1}><TopNft/></TabPanel>
            <TabPanel style={{height:'100%'}} value={2}><Reward/></TabPanel>
        </StyledTabContainer>
    );
};


const StyledTab = styled(Tab)`
  cursor: pointer;
  color: #DBDBDB;
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
  &:not(:last-child) {
  margin-right:30px;
  }
  &:hover {
    color: #53B7EA;
  }

  &:focus {
    color: #53B7EA;
  }

  &.${tabClasses.selected} {
    color: #53B7EA;
  }

  &.${buttonClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
  @media(max-width:700px) {
  font-size: 16px;
  }
`;


const StyledTabsList = styled(TabsList)(
    ({ theme }) => `
  display: flex;
  align-items: center;
  margin-bottom:30px;
  @media(max-width:700px) {
  margin-bottom:20px;
  padding-bottom: 10px;
  border-bottom:0.5px solid #C5C5C5;
  }
  `,
);
const StyledTabContainer = styled(TabContainer)(
    ({ theme }) => `
  display: flex;
  flex-direction:column;
  height:100%;
  `,
);

export default Tabs;
