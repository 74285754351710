import Home from "./page/home/Home";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import Nft from "./page/nft/Nft";
import Supports from "./page/supports/Support";
import AdminLayout from "./components/adminlayout/AdminLayout";
import Plan from "./page/plan/Plan";
import Trade from "./page/trade/Trade";
import Staking from "./page/staking/Staking";
import Farming from "./page/farming/Farming";
import Swap from "./page/swap/Swap";
import './index.scss'


function App() {
  return (
      <BrowserRouter>
        <div className="App">
            <AdminLayout>
                    <Routes>
                        <Route path='/'>
                            <Route index element={<Home/>} />
                            <Route path='/nft' element={<Nft/>} />
                            <Route path='/supports' element={<Supports/>} />
                            <Route path='/plan' element={<Plan/>} />
                            <Route path='/trade' element={<Trade/>} />
                            <Route path='/staking' element={<Staking/>} />
                            <Route path='/farming' element={<Farming/>} />
                            <Route path='/swap' element={<Swap/>} />
                        </Route>
                    </Routes>
            </AdminLayout>
        </div>
      </BrowserRouter>
  );
}

export default App;
