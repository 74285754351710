import React from 'react';
import './sidebar.scss'
import { Link,useLocation  } from 'react-router-dom';
import {Icon} from "../icon/Icon";
import {useMediaQuery} from "react-responsive";

function burgerMenu() {
    if(document.body.classList.contains('open')) {
        document.body.classList.remove('lock','open')
    } else {
        document.body.classList.add('lock','open')
    }
}

const Sidebar = () => {
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const location = useLocation();
    const isActive = (path) => {
        return location.pathname === path ? 'active' : '';
    };
    return (
        <div className='sidebar'>
            <button className='sidebar__close' onClick={() => burgerMenu()}>
                <Icon name='exit'/>
            </button>
                <ul>
                    <Link to='/'>
                        <li className={isActive('/')}>
                            <Icon name='dashboard'/>
                            <span>Dashboard</span>
                        </li>
                    </Link>
                    <Link to='/nft'>
                        <li className={isActive('/nft')}>
                            <Icon name="nft"/>
                            <span>NFT</span>
                        </li>
                    </Link>
                    <Link to='/plan'>
                    <li className={isActive('/plan')}>
                        <Icon name="savingPlan"/>
                        <span>Saving plan</span>
                    </li>
                    </Link>
                    <Link to='/trade'>
                    <li className={isActive('/trade')}>
                        <Icon name="trade"/>
                        <span>Trade</span>
                    </li>
                    </Link>
                    <Link to='/staking'>
                    <li className={isActive('/staking')}>
                        <Icon name="staking"/>
                        <span>Staking</span>
                    </li>
                    </Link>
                    <Link to='/farming'>
                    <li className={isActive('/farming')}>
                        <Icon name="farming"/>
                        <span>Farming</span>
                    </li>
                    </Link>
                    <Link to='/swap'>
                    <li className={isActive('/swap')}>
                        <Icon name="swap"/>
                        <span>SWAP</span>
                    </li>
                    </Link>
                    <Link to='/supports'>
                        <li className={isActive('/supports')}>
                            <Icon name="support"/>
                            <span>Support</span>
                        </li>
                    </Link>
                    { isMobile ? (
                    <li>
                        <Icon name='setting' className='sidebar_setting'/>
                        <span>Settings</span>
                    </li>
                    ) : null}
                </ul>
        </div>
    );
};

export default Sidebar;
