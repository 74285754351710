import React from 'react';
import './portfolio.scss'
import {PieChart, Pie, Cell, Label, ResponsiveContainer} from "recharts";
import { useMediaQuery } from 'react-responsive';
import {Icon} from "../icon/Icon";

const Portfolio = () => {
    const isMobile = useMediaQuery({ maxWidth: 767 });

    let data = [
        { id:0, name: 'Token', value: 34 ,color: '#7FFFD4' , position : 'PieTop'},
        { id:1, name: 'NFT', value: 24 ,color: '#EBB89F' , position : 'PieLeft'},
        { id:2, name: 'Wallet balance', value: 41 ,color: '#6DE8E6' , position : 'PieBottom'},
    ]
    let sum = 6936;

    return (
        <div className="PieChartContainer">
            <ResponsiveContainer width='100%' height='100%'>
            <PieChart>
                <Pie
                    cornerRadius={25}
                    data={data}
                    innerRadius={isMobile ? 55 : 75}
                    outerRadius={isMobile ? 75 : 95}
                    startAngle={40}
                    endAngle={400}
                    fill="#8884d8"
                    paddingAngle={8}
                    dataKey="value"
                >
                    {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry.color} />
                    ))}
                </Pie>
            </PieChart>
            </ResponsiveContainer>
                <div className="PieTextCenter">${sum}</div>
                { data.map((item) => (
                    <div key={item.id} className={`PieValueItem ${item.position}`}>
                        <div className="count">
                            {item.value}%
                            <Icon className={item.position} name={item.position}/>
                        </div>
                        <span className="name">{item.name}</span>
                    </div>
                ))}
        </div>
    );
};

export default Portfolio;
