import React from 'react';
import './watchlist.scss'
import {Icon} from "../icon/Icon";
const WatchList = ({title,count}) => {
    let color;
    switch (title) {
        case 'BUD':
            color = 'linear-gradient(138deg, #A6BFF4 0%, rgba(226, 243, 254, 0.80) 100%)';
            break;
        case 'Bitcoin':
            color = 'linear-gradient(138deg, #EBB89F 0%, rgba(235, 184, 159, 0.40) 100%)';
            break;
        case 'Ethereum':
            color = 'linear-gradient(138deg, #7FFFD4 0%, rgba(127, 255, 212, 0.30) 100%)';
            break;
        case 'BNB':
            color = 'linear-gradient(135deg, #6DE8E6 0%, rgba(109, 232, 230, 0.40) 100%)';
            break;
        default:
            color = 'white';
            break;
    }
    return (
        <div className='watchlist-item' style={{ background: color }}>
            <div className="watchlist-item__left">
                <div className="title">{title}</div>
                <div className="count">${count}</div>
            </div>
            <div className="watchlist-item__right">
                <Icon name={title}/>
            </div>
            <span className="flare"></span>
        </div>
    );
};

export default WatchList;
