import React from 'react';
import './staking.scss'
import {Icon} from "../../components/icon/Icon";
const Stalking = () => {
    return (
        <div className='main-page'>
            <h2 className="admin-content__title">Stalking</h2>
            <div className="main__wrapper">
                <div className="main__title">
                    In Development
                    <Icon name='time'/>
                </div>
            </div>
        </div>
    );
};

export default Stalking;
